import { useQuery } from "@apollo/client";
import React from "react";
import { useParams } from "react-router-dom";
import { QUERY_EMPLEADOS } from "../graphql/empleados";
const Empleados = () => {
  const { id } = useParams();
  console.log(id);
  const { loading, error, data } = useQuery(QUERY_EMPLEADOS, {
    variables: {
      noemp: id,
    },
  });
  if (loading) return <h1>cargando</h1>;

  const { empleados } = data;
  console.log(empleados);
  if (empleados.length === 0)
    return (
      <h5>
        La información solicitada por el momento no se encuentra disponible o
        está siendo procesada por favor intente más tarde
      </h5>
    );

  return (
    <>
      <div class="col-md-7 col-sm-12 clearfix pad-ex left-section">
        <div class="main-profile clearfix">
          <img
            alt=""
            class="img-circle pro-img"
            src={process.env.REACT_APP_AWS_BUCKET + empleados[0].fotografia}
          />
          <div class="text-wrapper">
            <h3>{empleados[0].nombre_completo}</h3>
            <h4>
              {empleados[0].puesto} {empleados[0].de}{" "}
              {empleados[0].departamento}
            </h4>
          </div>
          <div class="social-wrapper">
            {/* <a href="#">
          <i class="fa fa-facebook" aria-hidden="true"></i>
        </a>
        <a href="#">
          <i class="fa fa-twitter" aria-hidden="true"></i>
        </a>
        <a href="#">
          <i class="fa fa-linkedin" aria-hidden="true"></i>
        </a>
        <a href="#">
          <i class="fa fa-dribbble" aria-hidden="true"></i>
        </a>
        <a href="#">
          <i class="fa fa-behance" aria-hidden="true"></i>
        </a> */}
          </div>
        </div>
        <ul class="side-menu nav nav-tabs clearfix">
          <li class="active">
            <a data-toggle="tab" href="#home">
              <i class="fa fa-file-text" aria-hidden="true"></i>
              <br />
              <span>Ficha</span>
            </a>
          </li>
        </ul>
      </div>
      <div class="col-md-5 col-sm-12 pad-0">
        <div class="details-wrapper">
          <div class="tab-content pad-ex">
            <div
              id="home"
              class="tab-pane fade in active mCustomScrollbar"
              data-mcs-theme="dark"
            >
              <div class="about-us main-container">
                <div class="details-row row-m-bttom">
                  <span class="number">01</span>
                  <h3 class="row-heading">Información</h3>
                  <p></p>
                  <ul class="abt-focus">
                    <li>
                      <span class="st-line">Nombre :</span>
                      <span>{empleados[0].nombre_completo}</span>
                    </li>
                    <li>
                      <span class="st-line">Puesto :</span>
                      <span>
                        {empleados[0].puesto} {empleados[0].de}{" "}
                        {empleados[0].departamento}
                      </span>
                    </li>
                    <li>
                      <span class="st-line"> Dependencia :</span>
                      <span>{empleados[0].departamento}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Empleados;
