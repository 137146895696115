import React from "react";
import { Outlet } from "react-router-dom";
const Layout = () => {
  return (
    <section class="content">
      <div class="container content-container">
        <div class="row">
         
            <Outlet />
          
          
        <p>2022 - Municipo de Lázaro Cárdenas - Departamento de Informática</p>
        </div>
      </div>
    </section>
  );
};

export default Layout;
