import { Navigate } from "react-router-dom";
import Layout from "../layout/Layout";
import Empleados from "../pages/empleados";
const routes = () => [
  {
    path: "/",
    element: <Layout />,
    children: [{ path: "perfil/:id", element: <Empleados /> }],
  },

];

export default routes;
