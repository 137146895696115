import logo from "./logo.svg";
import "./App.css";
import Empleados from "./pages/empleados";
import { ApolloProvider } from "@apollo/client";
import client from "./config/apollo";
import routes from "./routes/routes";
import { useRoutes } from "react-router-dom";

function App() {
  const routing = useRoutes(routes());
  return (
    <ApolloProvider client={client}>
      {routing}
      {/* 
        
              <div class="main-profile clearfix">
                {/* <img alt="" class="img-circle pro-img" src="image/pro-img.png"> */}
      {/* <div class="text-wrapper">
                  <h3>Francisco Javier Guerrero Martínez</h3>
                  <h4>Auxiliar de Informática</h4>
                  <p>
                    Lorem ipsum dolor sit amet, erant sonet scripta te usu. Te
                    altera conceptam nam. Numquam maiorum aliquando et vel,
                    tractatos petentium dissentiet.
                  </p> */}
      {/* <img class="sign-img" alt="" src="image/sign.png"> */}
      {/* </div>
              </div>
              <ul class="side-menu nav nav-tabs clearfix">
                <li class="active">
                  <a data-toggle="tab" href="#home">
                    <i class="fa fa-home" aria-hidden="true"></i>
                    <br />
                    <span>Inicio</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section> */}
      {/* <Empleados /> */}
    
    </ApolloProvider>
  );
}

export default App;
