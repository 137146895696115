import { gql } from "@apollo/client";

export const QUERY_EMPLEADOS = gql`
  query GetEmpleados($noemp: String) {
    empleados(where: { noempl: { _eq: $noemp } }) {
      nombre_completo
      noempl
      puesto
      fotografia
      departamento
      de
    }
  }
`;
